//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-408:_9004,_5112,_5209,_2012,_9614,_8100,_2621,_7872;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-408')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-408', "_9004,_5112,_5209,_2012,_9614,_8100,_2621,_7872");
        }
      }catch (ex) {
        console.error(ex);
      }